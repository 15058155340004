import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { environment } from "../../config";


const Home = () => {

    const [type] = useQueryParam('type', StringParam);
    const navigate = useNavigate();
    const location = useLocation()


    useEffect(() => {

  
        if (type === 'unsubscribe') {
            navigate({
                pathname: "unsubscribe",
                search: location.search,
            });
        } else {
            if (environment === 'development') {
                console.log('dev enviroment')
                return
            }

            var currentURL = window.location.href
            var mainDomain = currentURL?.match(/:\/\/(.[^/]+)/)?.[1];
            console.log('main domain', mainDomain)
            const items = mainDomain?.split('.')
            const redirectTo = `https://${items?.[1]}.${items?.[2]}` 
            window.location.href = redirectTo

        }

    }, [type])

    return null


}


export default Home;