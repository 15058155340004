


const OneAppConfig = {
    development: {
        //baseUrl: 'https://pt5ltc93i1.execute-api.eu-central-1.amazonaws.com/pro/',
        baseUrl: 'http://192.168.0.208:3000/api',
    },
    production: {
        baseUrl: 'https://pt5ltc93i1.execute-api.eu-central-1.amazonaws.com/pro/api',
    }
}

type EnviromnenType = keyof typeof OneAppConfig

export const environment = (process.env.REACT_APP_ENVIRONMENT ?? 'development') as EnviromnenType


export default OneAppConfig?.[environment]