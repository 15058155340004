import { Button, Result } from "antd";
import { StringParam, useQueryParam } from "use-query-params";


const Unsubscribe = () => {

    const [success] = useQueryParam('success', StringParam);
    const [email] = useQueryParam('email', StringParam);

    if (success === '1') {
        console.log('success', email)
    }

    if (success === '1') {
        return (
            <Result
                status="info"
                title="You have been unsubscribed"
                subTitle="We're sorry to see you leave, in case you need to contact us for something please email us at contact@onelearning.app"
                icon={<img src="images/unsubscribe.jpg" />}
                extra={
                    <Button
                        onClick={() => {
                            window.location.href = '/'
                        }}
                        type="primary">Home</Button>}
            />
        )
    } else {
        return (
            <Result
                status="500"
                title="We are facing some issues"
                subTitle="We're sorry we could'nt unsubscribe, please try again after some time or contact us at contact@onelearning.app"
                //icon={<img src="/images/unsubscribe.jpg" />}
                extra={
                    <Button
                        onClick={() => {
                            window.location.href = '/'
                        }}
                        type="primary">Home</Button>}
            />
        )
    }


}


export default Unsubscribe;