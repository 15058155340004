
import OneAppConfig from '.'

const AppLinks = {
    unsubscribe: `${OneAppConfig.baseUrl}/email/manage/unsubscribe-email`
}

enum AppRoutes {
    home = '/',
    unsubscribe = 'unsubscribe',
    confirmUnsubscribe = 'confirm-unsubscribe',
    unsubscribeSuccess = 'unsubscribe-success',
    unsubscribeError = 'unsubscribe-error'
}

export {
    AppLinks,
    AppRoutes
}