import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import './App.css';
import Home from './screens/Home';
import Unsubscribe from './screens/Unsubscribe';
import { QueryParamProvider } from 'use-query-params';
import ConfirmUnsubscribe from "./screens/ConfirmUnsubscribe";
import { AppRoutes } from "./config/constants";
import UnsubscribeSuccess from "./screens/UnSubscribeSuccess";
import UnsubscribeError from "./screens/UnsubscribeError";


function App() {

  return (
    <div className="App">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path={`/${AppRoutes.unsubscribe}`} element={<Unsubscribe />} />
            <Route path={`/${AppRoutes.unsubscribeSuccess}`} element={<UnsubscribeSuccess />} />
            <Route path={`/${AppRoutes.unsubscribeError}`} element={<UnsubscribeError />} />
            <Route path={`/${AppRoutes.confirmUnsubscribe}`} element={<ConfirmUnsubscribe />} />
          </Routes>
        </QueryParamProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
